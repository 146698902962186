<template>
    <div>
        <div class="row page-title-header">
            <div class="col-12">
                <div class="page-header">
                    <b-breadcrumb class="m-0">
                        <b-breadcrumb-item :to="{
                            name: 'DashboardHome',
                        }">
                            <i class="fa fa-home"></i>
                            銷售應用中心
                        </b-breadcrumb-item>
                        <b-breadcrumb-item active>發票列表</b-breadcrumb-item>
                    </b-breadcrumb>
                </div>
            </div>
        </div>
        <b-card>
            <b-overlay :show="showLoading" rounded="sm">
                <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
                    <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
                        發票列表
                    </h4>
                    <div class="
                        col-12 col-xl-8
                        d-flex
                        flex-column flex-xl-row
                        align-items-start align-items-xl-center
                    ">
                        <b-form-select
                            v-model="selectedMerchantID"
                            :options="merchants"
                            @change="handleSelectMerchant"
                            :disabled="merchants.length == 2">
                        </b-form-select>
                        <b-input-group class="ml-0 ml-xl-4">
                          <b-form-input placeholder="搜尋發票號碼、會員名稱、手機、外部編碼" v-model="keyword"
                            v-on:keyup.enter="fetchInvoices(currentPage)"></b-form-input>
                          <b-input-group-append>
                            <b-button @click="fetchInvoices(currentPage)"><i class="fa fa-search"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                    </div>

                </div>
                <b-table responsive striped hover :items="invoices" :fields="fields" @sort-changed="handleSort">
                    <template #cell(name)="data">
                        <router-link :to="{
                            name: 'InvoiceDetail',
                            params: {
                                invoice_id: data.item.id,
                            },
                        }">
                            <div class="mb-1">{{ data.item.title }}</div>
                            <div>{{ data.item.sale_page_code }}</div>
                        </router-link>
                    </template>
                    <template #cell(created_at)="data">
                        <div class="mb-1">{{ formatDate(data.item.created_at) }}</div>
                    </template>
                    <template #cell(invoice_date)="data">
                        <div class="mb-1">{{ formatDate(data.item.invoice_date) }}</div>
                    </template>
                    <template #cell(branch)="data">
                        <div class="mb-1">
                            {{ data.item.branch ? data.item.branch.name : "" }}
                        </div>
                        <div>
                            {{ data.item.branch ? data.item.branch.branch_code : "" }}
                        </div>
                    </template>
                    <template #cell(arrange)="data">
                        <b-button class="ml-2" variant="primary" v-if="checkPermission([consts.PRODUCT_LIST_VIEW])" :to="{
                            name: 'InvoiceDetail',
                            params: {
                                invoiceId: data.item.id,
                            },
                        }">查看
                        </b-button>
                    </template>
                </b-table>
                <div class="d-flex justify-content-center" style="margin-top: 80px">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
                </div>
            </b-overlay>
        </b-card>
    </div>
</template>
<script>

import merchantApi from "../../../apis/merchant";
import invoiceApi from "../../../apis/invoice";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
    data() {
        return {
            consts,
            showLoading: false,
            selectedMerchantID: null,
            merchants: [
                { value: null, text: "請選擇" },
            ],
            currentPage: 1,
            totalRows: 1,
            perPage: 10,
            fields: [
                {
                    key: "customer_name",
                    label: "會員名稱",
                    sortable: true,
                    sortKey: "customer_name"
                },
                {
                    key: "invoice_number",
                    label: "發票號碼",
                },
                {
                    key: "invoice_date",
                    label: "開立日期",
                    sortable: true,
                    sortKey: "invoice_date",
                    sortDirection: 'desc'
                },
                {
                    key: "random",
                    label: "隨機碼",
                },
                {
                    key: "seller_name",
                    label: "開立機構",
                },
                {
                    key: "total_price",
                    label: "金額",
                },
                {
                    key: "invoice_year",
                    label: "年份",
                },
                {
                    key: "invoice_period",
                    label: "期別",
                    sortable: true,
                    sortKey: "invoice_period"
                },
                {
                    key: "created_at",
                    label: "建檔日期",
                    sortable: true,
                    sortKey: "created_at"
                },
                {
                    key: "arrange",
                    label: "管理",
                },
            ],
            invoices: [],
            permissionChecker: new PermissionChecker(),
            keyword: null,
            sortBy: "invoice_date",
            orderBy: "desc",
        };
    },
    mounted() {
        this.fetchMerchants()
    },
    watch: {
        currentPage: function () {
          this.fetchInvoices()
        },
    },
    methods: {
        handleSort(ctx) {
          this.sortBy = ctx.sortBy;
          this.orderBy = ctx.sortDesc ? "desc" : "asc";
          this.currentPage = 1;
          this.fetchInvoices();
        },
        async fetchInvoices() {
            if (!this.selectedMerchantID) return

            this.showLoading = true;
            try {
                const params = {
                    page: this.currentPage,
                    sort_by: this.sortBy,
                    order_by: this.orderBy,
                };
                if (this.keyword) params.keyword = this.keyword;
                if (this.selectedMerchantID) params.merchant_id = this.selectedMerchantID

                const { data } = await invoiceApi.getInvoices(params);

                this.invoices = data.data.map(invoice => ({ customer_name: invoice?.customer?.name, ...invoice }));
                this.totalRows = data.meta.total;
                this.perPage = data.meta.per_page;
            } catch (error) {
                console.error(error);
                alert("取得發票資料錯誤");
            }
            this.showLoading = false;
        },
        async fetchMerchants() {
            const { data } = await merchantApi.list();

            this.merchants = [
                { value: null, text: "請選擇" },
                ...data.data.map((merchant) => {

                    return {
                        value: merchant.id,
                        text: `${merchant.name} (${merchant.type_name})`,
                    };
                }),
            ];
            let defaultMerchant
            if (data.data.length == 1) {
                defaultMerchant = data.data[0]
            } else {
                defaultMerchant = data.data.filter(merchant => merchant.type == 'LINE').shift()
            }

            if (this.$route.query.merchant_id) {
                this.selectedMerchantID = this.$route.query.merchant_id

                var hasMerchant = this.merchants.find((item) => {
                    return item.value === this.selectedMerchantID
                })

                if (!hasMerchant) {
                    this.$swal
                        .fire({
                            type: "warning",
                            title: "查無此通路",
                            confirmButtonText: "確定",
                            confirmButtonColor: "#d33",
                            reverseButtons: true,
                        })
                    return
                }

                this.fetchInvoices()
                return
            }

            if (defaultMerchant) {
                this.selectedMerchantID = defaultMerchant.id;
                this.fetchInvoices()
              }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD HH:mm");
            }
        },
        checkPermission(permissions) {
            return this.permissionChecker.check(permissions);
        },
        handleSelectMerchant() {
            this.$router.replace({ query: { merchant_id: this.selectedMerchantID } })

            this.fetchInvoices()
        }
    },
};
</script>

<style>
.modal-backdrop {
    opacity: 0.5;
}
</style>

